import classes from "./Portfolio.module.css";
import Content from "./Content";
import PortfolioSingle from "../components/pfolioelement/PortfolioSingle";
import SideMenuMain from "../components/SidebarMenu/SideMenuMain";

import { Link } from "react-router-dom";
import UpdateStatus from "../UpdateStatus";
import GSD from '../GlobalSiteData';

import SiteHelmet from '../utilities/SiteHelmet';

function Portfolio() {
  const portfolioRestUrl =
  GSD[0].BLOG_URL + "wp-json/wp/v2/portfolio?_embed&acf_format=standard&per_page=100";
  const portfolioJson = UpdateStatus(portfolioRestUrl);
  // console.log (portfolioJson);

  /*
  function handleClickUp(e) {
    e.preventDefault();
    console.log("clicked Up");
  }
  function handleClickDown(e) {
    e.preventDefault();
    console.log("clicked Down");
  }
  */

  return (
    <>
      <SideMenuMain menuBackImg={"none"} />
      <SiteHelmet searchstr="portfolio" />

      <div className="portfolioBack">
        <Content>
          <div className="page-title-space">
            <h1 className={classes.pagetitle}>Recent Works ...</h1>
          </div>

          <div className={classes.gridrow}>
            {Object.values(portfolioJson).map((pfolio, ii) => {
              return (
                <div key={ii} className={classes.gridcol}>
                  <Link to={pfolio.acf.url}>
                    <PortfolioSingle
                      image={pfolio.acf.image.url}
                      alttext={pfolio.acf.title}
                    />
                  </Link>
                </div>
              );
            })}
          </div>
        </Content>
        <div className={classes.pageBottom}></div>
      </div>
    </>
  );
}

export default Portfolio;
