import UpdateStatus from "../../UpdateStatus";
import SidebarItem from "./SidebarItem";
import SidebarLogo from "./SidebarLogo";
import classes from "./SideMenuMain.module.css";

// import portfolioSitesData from '../../portfolioSites/portfolioSitesData';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Hamburger from "./Hamburger";
import GSD from "../../GlobalSiteData";

// Include Blog Menu item components
import ShowMenuInternal from "./SideMenuMain_subcomponents/ShowMenuInternal";
import ShowMenuWP from "./SideMenuMain_subcomponents/ShowMenuWP";

function SideMenuMain(props) {
  const portfolioRestUrl =
    GSD[0].BLOG_URL +
    "wp-json/wp/v2/portfolio?_fields=acf&_embed&acf_format=standard&per_page=100";
  const portfolioJson = UpdateStatus(portfolioRestUrl);

  const [showMenu, setShowMenu] = useState(
    window.innerWidth > 768 ? true : false
  );

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768) {
        setShowMenu(true);
      } else {
        setShowMenu(false);
      }
    }

    window.addEventListener("resize", handleResize);
  });

  return (
    <>
      <div className="ham" onClick={toggleMenu}>
        <Hamburger />
      </div>

      <div
        className="sidebarmain"
        style={{
          backgroundImage:
            props.menuOverlayCss + ",url(" + props.menuBackImg + ")",
          display: showMenu ? "block" : "none",
        }}
      >
        <div>
          <SidebarLogo />
        </div>

        <div className={classes.itemscontainer}>
          <ul>
            <SidebarItem link="/" itemtext="Home" />
            <SidebarItem link="/portfolio" itemtext="Portfolio" />
            <SidebarItem link="/react" itemtext="React" />
            <SidebarItem link="/wordpress" itemtext="WordPress" />
            <SidebarItem link="/testimonials" itemtext="Testimonials" />

            {GSD[0].SHOW_BLOG ? <ShowMenuInternal /> : <ShowMenuWP />}

            <SidebarItem link="/quicknotes" itemtext="Quick Notes" />
            <SidebarItem link="/contact" itemtext="Contact" />
          </ul>

          <div className={classes.pfseparator}></div>
          <h6 className={classes.pftitle}>Portfolio Sites</h6>

          <div className={classes.pfoliolinks}>
            {Object.values(portfolioJson).map((ss, ii) => {
              return (
                <p key={ii} className={classes.psinglelink}>
                  <Link to={ss.acf.url}>
                    {ss.acf.title.split(" ").map((titleword, count) => {
                      return (
                        <span key={count}>
                          {titleword}
                          <br />
                        </span>
                      );
                    })}
                  </Link>
                </p>
              );
            })}

          </div>
        </div>
      </div>
    </>
  );
}

export default SideMenuMain;
