import classes from "./Content.module.css";
import "./Content.css";
import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

/* Main content function - just holds all pages content
 */

function Content(props) {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true);
  }, [] );

  function handleLoad() {
    setInProp(true);
  }

  return (
    <CSSTransition key={window.location.href} in={inProp} timeout={1400} classNames="mycontent">
      <div className={classes.content}  style={{ visibility: inProp ? "visible" : "hidden" }} onLoad={handleLoad}>
        <div className={classes.contentContainer}>{props.children}</div>
      </div>
    </CSSTransition>
  );
}

export default Content;
