import GSD from "../GlobalSiteData";
import UpdateStatus from "../UpdateStatus";
import GlobalSiteData from '../Globals';

function GetSiteSeo() {
  const SeoUrl =
    GSD[0].BLOG_URL +
    "wp-json/wp/v2/helmetdata?_fields[]=title&_fields[]=acf&acf_format=standard&per_page=100";

  GlobalSiteData.seo = UpdateStatus(SeoUrl);
  // console.log(GlobalSiteData.seo);

  return "";
}

export default GetSiteSeo;
