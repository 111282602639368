import classes from './Hamburger.module.css';

function Hamburger() {
  return (
    <div className={classes.hamburgerContainer}>
      <div className={classes.hamburger}>
        <div className={classes.burger} />
        <div className={classes.burger} />
        <div className={classes.burger} />
      </div>
    </div>
  );
}

export default Hamburger;
