import classes from './PText.module.css';

function PText(props) {
  return (
    <>
      <div className={classes.rowContainer}>
        <div className={classes.boxLeft}>
          <h3 className={classes.title}>{props.title1}</h3>
          <p className={classes.text}>{props.t1}</p>
        </div>
        <div className={classes.boxCenter}>
          <h3 className={classes.title}>{props.title2}</h3>

          <ul className={classes.text}>
            {props.t2.split('\n').map((line, index) => {
              return <li key={index}>{line}</li>;
            })}
          </ul>

        </div>
        <div className={classes.boxRight}>
          <h3 className={classes.title}>{props.title3}</h3>

          <ul className={classes.text}>
            {props.t3.split('\n').map((line, index) => {
              return <li key={index}>{line}</li>;
            })}
          </ul>
          
        </div>
      </div>
    </>
  );
}

export default PText;
