import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";

import "./App.css";
import Services from "./pages/ServicesWordPress";
import ServicesReact from "./pages/ServicesReact";
import Testimonials from "./pages/Testimonials";
import Blog from "./pages/Blog";
import QuickNotes from './pages/QuickNotes';
import Contact from "./pages/Contact";

// import portfolioSitesData from './portfolioSites/portfolioSitesData';
import PortfolioSite from "./portfolioSites/PortfolioSite";
import Page404 from "./pages/Page404";

import UpdateStatus from "./UpdateStatus";
import GSD from "./GlobalSiteData";

function App() {
  const portfolioRestUrl =
    GSD[0].BLOG_URL +
    "wp-json/wp/v2/portfolio?_fields=acf&_embed&acf_format=standard&per_page=100";
  const portfolioJson = UpdateStatus(portfolioRestUrl);
  // console.log(portfolioJson);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/react" element={<ServicesReact />} />
        <Route path="/wordpress" element={<Services />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/quicknotes" element={<QuickNotes />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Page404 />} />

        {Object.values(portfolioJson).map((ss, ii) => {
          return (
            <Route
              key={ii}
              path={ss.acf.url}
              element={<PortfolioSite sindex={ii} />}
            />
          );
        })}
      </Routes>
    </div>
  );
}

export default App;
