import React, { useRef } from 'react';
/* import emailjs from 'emailjs-com'; */
import emailjs from '@emailjs/browser';

import classes from './contactForm.module.css';
import {useState} from 'react';

export const ContactUs = () => {
  var GLOCATION_URL = "https://geolocation-db.com/json/";
  const [thankYou, setThankYou] = useState (false);

  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();

    fetch(GLOCATION_URL)
      .then(response => response.json())
      .then((jsonData) => {
        form.current.message.value += "...." + JSON.stringify(jsonData, false, 4);

        emailjs
          .sendForm(
            'service_gta7fkc',
            'template_dixb404',
            form.current,
            'user_ztwPti4ujMeCdYx436VA3'
          )
          .then(
            (result) => {
              console.log(result.text);
              setThankYou (true);
            },
            (error) => {
              console.log(error.text);
              setThankYou (false);
            }
          );
        e.target.reset();
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <>
      <form ref={form} onSubmit={sendEmail} className={classes.formContainer}>
        <div className={classes.fieldContainer}>
          <input type='text' placeholder='Name ...' name='from_name' />
        </div>
        <div className={classes.fieldContainer}>
          <input type='email' placeholder='Email ...' name='from_email' />
        </div>
        <div className={classes.fieldContainer}>
          <textarea placeholder='Message ...' name='message' />
        </div>
        <div className={classes.fieldContainer}>
          <input className={classes.bSend} type='submit' value='Send Message' />
        </div>
      </form>
      <div>
        <p style={{
          color: "white",
          fontFamily: "dosis",
          fontWeight: "200",
          visibility: thankYou ? "visible" : "hidden"
        }}>
          Thank You for your message :)
        </p>
      </div>
    </>
  );
};
