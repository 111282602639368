import classes from './HowSingle.module.css';

function HowSingle(props) {
  return (
    <div className={classes.container}>
      <div className={classes.title} style={{ width: props.w1 }}>
        {props.title}
      </div>
      <div className={classes.description} style={{}}>
        {props.description.split('\n').map((value, index) => {
          return (
            <span key={index}>
              <p>{value}</p>
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default HowSingle;
