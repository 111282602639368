import classes from './PBottom.module.css';
import PSingleImg from './PSingleImg';

function PBottom(props) {
  return (
    <div className={classes.bottomContainer}>
      <div className={classes.singleColumn}>
        <PSingleImg image={props.img1} ovF={props.overFlowImages} alttext='' />
      </div>
      <div className={classes.singleColumn}>
        <PSingleImg image={props.img2} ovF={props.overFlowImages} alttext='' />
      </div>
      <div className={classes.singleColumn}>
        <PSingleImg image={props.img3} ovF={props.overFlowImages} alttext='' />
      </div>
    </div>
  );
}

export default PBottom;
