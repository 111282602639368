import classes from './PHero.module.css';
import PSingleImg from './PSingleImg';

function PHero(props) {
  return (
    <div
      className={classes.heroContainer}
      style={{backgroundImage: props.herooverlaycss + ',url(' + props.heroback + ')'}}
    >
      <div className={classes.hLeft}>
        <div className={classes.title}>
          <h1>{props.title}</h1>
        </div>
        <h6 className={classes.url}>{props.url}</h6>
        <a
          href={props.url}
          target='_blank'
          rel='noreferrer'
          className={classes.visitbutton}
        >
          Visit Website
        </a>
      </div>
      <div className={classes.hRight}>
        <PSingleImg image={props.image} ovF={true} alttext={props.title} />
      </div>
    </div>
  );
}

export default PHero;
