import classes from "./Home.module.css";
import Content from "./Content";
import SideMenuMain from "../components/SidebarMenu/SideMenuMain";
import SiteHelmet from "../utilities/SiteHelmet";

import { isSafari } from "react-device-detect";

function Home() {

  /* If it is Safari Browser dont use Serbian Latin 
   * characters
   */
  
  function myNameonSafari ()
  {
    if (isSafari) {
      return (<h1 className={classes.myname}>Nebojsa Zivkovic</h1>);
    }
    else {
      return (<h1 className={classes.myname}>Nebojša Živković</h1>);
    }
  }

  return (
    <>
      <SideMenuMain menuBackImg={"none"} />
      <SiteHelmet searchstr="home" />

      <div className="homeBack">
        <Content>
          <div className={classes.fullh}>
            <div className={classes.signaturecontainer}>

              {myNameonSafari()}

              <p className={classes.myslogan}>
                I’m an independent&nbsp;
                <span className={classes.highslogan}>
                  full stack web developer based in Belgrade, Serbia
                </span>
                . I specialize in ReactJS, Bootstrap, HTML/CSS, WordPress, PHP,
                MySQL, web server administration, and web design.&nbsp;
                <span className={classes.highslogan}>
                  <br />
                  <br />I can make your site or app simple, beautiful, and easy
                  to use.
                </span>
              </p>
            </div>
          </div>
        </Content>
      </div>
    </>
  );
}

export default Home;
