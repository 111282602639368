import classes from "./Testimonials.module.css";
import Content from "./Content";
import SideMenuMain from "../components/SidebarMenu/SideMenuMain";

// import TestimonialData from "./TestimonialsData";
import UpdateStatus from "../UpdateStatus";
import TestimonialSingle from "../components/testimonialelement/TestimonialSingle";
import GSD from "../GlobalSiteData";

import SiteHelmet from '../utilities/SiteHelmet';

function Testimonials() {
  const testimonialsRestUrl =
    GSD[0].BLOG_URL + "wp-json/wp/v2/testimonial-data?per_page=100";

  const borderColors = [
    "#902e29",
    "#5f4983",
    "#1c5c8f",
    "#825c0c",
    "#4e6931",
    "#75760f",
  ];

  const randColor = () => {
    return borderColors[Math.floor(Math.random() * 6)];
  };

  const testimonialJson = UpdateStatus(testimonialsRestUrl);
  //console.log(testimonialJson);

  return (
    <>
      <SideMenuMain menuBackImg={"none"} />
      <SiteHelmet searchstr="testimonials" />

      <div className="testimonialsBack">
        <Content>
          <div className="page-title-space">
            <h1 className={classes.pagetitle}>Testimonials ...</h1>
          </div>

          <div className={classes.gridrow}>
            {Object.values(testimonialJson).map((tt, ii) => {
              return (
                <div
                  key={ii}
                  className={classes.gridcol}
                  style={{ borderColor: randColor() }}
                >
                  <TestimonialSingle
                    text={tt.acf.Text}
                    project={tt.acf.projectproject}
                    when={tt.acf.when}
                    name={tt.acf.name}
                  />
                </div>
              );
            })}
          </div>
        </Content>
        <div className={classes.pageBottom}></div>
      </div>
    </>
  );
}

export default Testimonials;
