import SidebarItem from '../SidebarItem';

function ShowMenuWP() {
  return (
    <>
      <SidebarItem link="/blog" itemtext="Notes" />
    </>
  );
}

export default ShowMenuWP;