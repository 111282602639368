
const GSD = [
    {
        /* True -> shows WordPress blog site menu and calls ShowMenuWp component
           False -> shows internal blog site and calls <Blog /> Component
        */
        SHOW_BLOG: true,
        /* Rest Api WordPress blog URL
        */
        BLOG_URL: 'https://hl.zivkovic.xyz/',
    }
]

export default GSD;

