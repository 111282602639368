const ContactData = [
    {
      id: 's1',
      title: 'About Me',
      description:
        'I am a full-time web developer. I work exclusively as a freelancer.\n' + 
        'My focus is on ReactJS, Next.js, pure HTML/CSS/JS, and WordPress front/back programming. I also help with troubleshooting and support.\n' +
        'I started my career as a Unix system administrator and C system developer before moving into web development.\n' +
        'Ubuntu Server 22.04 is my main web development platform, and I use Pop!_OS on my desktop machines.\n' +
        'I live and work in Belgrade, Serbia.\n',
      link: '',
    },
]

export default ContactData;
