import SiteHelmet from "../utilities/SiteHelmet";
import classes from "./ServicesReact.module.css";
import "./Services.css";
import Content from "./Content";

import SideMenuMain from "../components/SidebarMenu/SideMenuMain";

import ServicesSingle from "../components/services/ServicesSingle";
import MiscSingle from "../components/services/MiscSingle";
import HowSingle from "../components/services/HowSingle";

import UpdateStatus from '../UpdateStatus';
import GSD from "../GlobalSiteData";

function ServicesReact () {
  const servicesListRestUrl =
    GSD[0].BLOG_URL +
    "wp-json/wp/v2/serviceslist?_embed=1&acf_format=standard&per_page=100";
  const servicesListJson = UpdateStatus(servicesListRestUrl);

  const servicesHowRestUrl =
    GSD[0].BLOG_URL +
    "wp-json/wp/v2/serviceshow?_embed=1&acf_format=standard&per_page=100";
  const servicesHowJson = UpdateStatus(servicesHowRestUrl);

  const servicesMiscRestUrl =
    GSD[0].BLOG_URL +
    "wp-json/wp/v2/servicesmisc?_embed=1&acf_format=standard&per_page=100";
  const servicesMiscJson = UpdateStatus(servicesMiscRestUrl);

  // console.log (servicesMiscJson);

  const borderColors = [
    "#8bc34a",
    "#8261b7",
    "#2196f3",
    "#edf000",
    "#f44336",
    "#ff9900",
  ];

  const randColor = () => {
    return borderColors[Math.floor(Math.random() * 6)];
  };

  return (
    <>
      <SideMenuMain menuBackImg={"none"} />
      <SiteHelmet searchstr="servicesreact" />

      <div className="servicesBack">
        <Content>
          <div className="page-title-space servicesTitleBack">
            <h1 className={classes.pagetitle}>React Development ...</h1>
          </div>

          <div className={classes.gridrow}>
            {Object.values(servicesListJson).map((srvElem, ii) => {
              /* Display only services that are in react category */
              return srvElem._embedded["wp:term"]["0"]["0"].name === "react" ? (
                /* Grid element render */
                <div
                  key={ii}
                  className={classes.gridcol}
                  style={{ borderColor: randColor() }}
                >
                  <ServicesSingle
                    title={srvElem.acf.title}
                    description={srvElem.acf.description}
                    category={srvElem._embedded["wp:term"]["0"]["0"].name}
                  />
                </div>
              ) : (
                /* Hidden Grid element that belongs to different category */
                <div key={ii} className={classes.noDisplay}></div>
              );
            })}
          </div>

          <div className="page-title-space servicesTitleBack">
            <h1 className={classes.pagetitle}>How ...</h1>
          </div>

          <div>
            {Object.values(servicesHowJson).map((srvElem, ii) => {
              /* Display only services that are in wp category */
              return srvElem._embedded["wp:term"]["0"]["0"].name === "react" ? (
              /* Grid element render */
                <div key={ii}>
                  <HowSingle
                    title={srvElem.acf.title}
                    description={srvElem.acf.description}
                    w1={srvElem.acf.width}
                  />
                </div>
              ) : (
                /* Hidden Grid element that belongs to different category */
                <div key={ii} className={classes.noDisplay}></div>
              );
            })}
          </div>

          <div className="page-title-space servicesTitleBack">
            <h1 className={classes.pagetitle}>What Else ...</h1>
          </div>

          <div className={classes.gridrowMiscSection}>
            {Object.values(servicesMiscJson).map((srvElem, ii) => {
              /* Display only services that are in wp category */
              return srvElem._embedded["wp:term"]["0"]["0"].name === "react" ? (
                /* Grid element render */
                <div key={ii} className={srvElem.acf.gridtype}>
                  <MiscSingle
                    icon={srvElem.acf.iconimg.url}
                    iconcolorcode={srvElem.acf.iconcolor}
                    title={srvElem.acf.title}
                    description={srvElem.acf.description}
                    tcolor={srvElem.acf.tcolor}
                    bcolor={srvElem.acf.bcolor}
                  />
                </div>
              ) : (
                /* Hidden Grid element that belongs to different category */
                <div key={ii} className={classes.noDisplay}></div>
              );
            })}
          </div>

        </Content>

        <div className={classes.pageBottom}></div>
      </div>
    </>
  );
}

export default ServicesReact;
