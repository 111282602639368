import { useState, useEffect, useRef } from "react";

function UpdateStatus(ppRestUrl) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const restUrl = useRef(ppRestUrl);

  function fetchHandler(urlToFetch) {
    fetch(urlToFetch)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => setData(data))
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchHandler(restUrl.current);
  }, []);

  var strJson = JSON.stringify(data);
  var objJson = JSON.parse(strJson);

  if (loading) return "";
  if (error) return "";

  return objJson;
}

export default UpdateStatus;
