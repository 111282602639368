import classes from "./PSingleImg.module.css";

function PSingleImg(props) {
  return (
    <div
      className={classes.elementcontainer}
      style={{
        overflow: props.ovF ? null : "hidden",
        height: props.ovF ? null : "auto",
      }}
    >
      <img
        src={props.image}
        alt={props.alttext}
        className={classes.pimage}
        style={{
          width: props.ovF ? "96%" : "100%",
        }}
      />
    </div>
  );
}

export default PSingleImg;
