import classes from './TestimonialSingle.module.css';

function TestimonialSingle(props) {
  const colorsArray = [
    '#f43e34',
    '#cdb1fb',
    '#92ccfa',
    '#fdae38',
    '#baf878',
    '#eef027',
  ];

  const randColor = () => {
    return colorsArray[Math.floor(Math.random() * 6)];
  };

  return (
    <div className={classes.elementcontainer}>  
      <h6 className={classes.testimonialText}>{props.text}</h6>

      <p className={classes.prText}>Project at Guru.com:</p>
      <p className={classes.testimonialDate}>-</p>
      <p className={classes.testimonialProject} style={{ color: randColor() }}>
        {props.project}
      </p>

      <p className={classes.testimonialDate}>-</p>
      <p className={classes.testimonialName} style={{ color: randColor() }}>
        {props.name}
      </p>
      <p className={classes.testimonialDate}>{props.when}</p>
    </div>
  );
}

export default TestimonialSingle;
