
const SeoData = [];
const OtherData1 = [];
const VariousData = [];

const GlobalSiteData = {
  seo: SeoData,
  other: OtherData1,
  various: VariousData,
};

export default GlobalSiteData;
