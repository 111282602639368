import { Helmet } from "react-helmet";
import GlobalSiteData from "../Globals";

function SiteHelmet(props) {
  var lvTitle, lvDesc, lvOgSiteName, lvOgTitle, lvOgUrl, lvOgImage;

  // console.log (GlobalSiteData.seo);

  function getSeoValueByName(ppName) {
    var ii;
    for (ii = 0; ii < GlobalSiteData.seo.length; ii++) {
      if (GlobalSiteData.seo[ii].title.rendered === ppName) {
        lvTitle = GlobalSiteData.seo[ii].acf.sitetitle;
        lvDesc = GlobalSiteData.seo[ii].acf.description;
        lvOgTitle = GlobalSiteData.seo[ii].acf.ogtitle;
        lvOgUrl = GlobalSiteData.seo[ii].acf.ogurl;
        lvOgSiteName = GlobalSiteData.seo[ii].acf.ogsitename;
        lvOgImage = GlobalSiteData.seo[ii].acf.ogimage;
        return;
      }
    }
  }

  return (
    <>
      <Helmet>
        {getSeoValueByName (props.searchstr)}

        <title>{lvTitle}</title>
        <meta name="description" content={lvDesc} />
        <meta name="author" content="Nebojsa D. Zivkovic" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={lvOgTitle} />
        <meta property="og:url" content={lvOgUrl} />
        <meta property="og:site_name" content={lvOgSiteName} />
        <meta property="og:image" content={lvOgImage} />

      </Helmet>
    </>
  );
}

export default SiteHelmet;
