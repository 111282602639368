import classes from "./PortfolioSingle.module.css";

function PortfolioSingle(props) {
  return (
      <div className={classes.elementcontainer}>
        <img src={props.image} alt={props.alttext} className={classes.pimage} />
      </div>
  );
}

export default PortfolioSingle;
