import React from "react";
import {createRoot} from "react-dom/client";

import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";
import ScrollToTop from "./ScrollToTop";
import GetSiteSeo from './utilities/GetSiteSeo';

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <GetSiteSeo />
    <App />
  </BrowserRouter>
);
