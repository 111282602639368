import { ContactUs } from '../components/mail/contactForm';
import SiteHelmet from '../utilities/SiteHelmet';
import classes from './Contact.module.css';
import Content from './Content';

import SideMenuMain from '../components/SidebarMenu/SideMenuMain';

import ContactData from './ContactData';
import twitterImage from '../static/icons/social_twitter.svg';
import linkedinImage from '../static/icons/social_linkedin.svg';

import guruImage from '../static/platforms/guru.png';
// import freelancerImage from '../static/platforms/freelancer.png';
// import laborxImage from '../static/platforms/laborx.png';
import upworkImage from '../static/platforms/upwork.png'

const twitterLink = 'https://twitter.com/NebojsaZi';
const linkedinLink = 'https://www.linkedin.com/in/nebojsazivkovic/';

const upworkLink = 'https://www.upwork.com/freelancers/~01400482d2052c69b5';
const guruLink = 'https://www.guru.com/freelancers/nebojsa-zivkovic';
//const freelancerLink = 'https://www.freelancer.com/u/BeogateTech';
//const laborxLink = 'https://laborx.com/freelancers/users/id36975';

function Contact() {
  return (
    <>
      <SideMenuMain menuBackImg={'none'} />
      <SiteHelmet searchstr="contact" />

      <div className='contactBack'>
        <Content>
          <div className='page-title-space'>
            <h1 className={classes.pagetitle}>Contact ...</h1>
          </div>

          <div className={classes.contactContainer}>
            <div className={classes.contactLeft}>
              {ContactData.map((aboutElem) => {
                return (
                  <div key={aboutElem.id}>
                    <div className={classes.title}>{aboutElem.title}</div>
                    <div className={classes.desc}>
                      {aboutElem.description.split('\n').map((value, index) => {
                        return (
                          <span key={index}>
                            <p>{value}</p>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              <div className={classes.separator}></div>

              <div className={classes.title}>Social</div>

              <div className={classes.socialContainer}>
                <div className={classes.socialBox}>
                  <a href={twitterLink} target='_blank' rel='noreferrer'>
                    <img src={twitterImage} alt='' />
                  </a>
                </div>
                <div className={classes.socialBox}>
                  <a href={linkedinLink} target='_blank' rel='noreferrer'>
                    <img src={linkedinImage} alt='' />
                  </a>
                </div>
              </div>

              <div className={classes.separator}></div>

              <div className={classes.title}>You can hire me on:</div>

              <div className={classes.platformContainer}>
                <div className={classes.platformBoxNormal}>
                  <a href={upworkLink} target='_blank' rel='noreferrer'>
                    <img src={upworkImage} alt='' />
                  </a>
                </div>
              </div>

              <div className={classes.platformContainerDELETED}>
                <div className={classes.platformBox}>
                  <a href={guruLink} target='_blank' rel='noreferrer'>
                    <img src={guruImage} alt='' />
                  </a>
                </div>
              </div>

            </div>
            <div className={classes.contactRight}>
              <div className={classes.title}>Message me ...</div>
              <ContactUs />
            </div>
          </div>
        </Content>
        <div className={classes.pageBottom}></div>
      </div>
    </>
  );
}

export default Contact;
