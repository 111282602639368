import classes from './MiscSingle.module.css';

function MiscSingle(props) {
  function iconClass(iconCode) {
    switch (iconCode) {
      case '0':
        return classes.srvIcon0;
      case '1':
      default:
        return classes.srvIcon1;
    }
  }

  return (
    <div
      className={classes.srvElementContainer}
      style={{ backgroundColor: props.bcolor }}
    >
      <img src={props.icon} alt='' className={iconClass(props.iconcolorcode)} />
      <h4 className={classes.srvTitle} style={{ color: props.tcolor }}>
        {props.title}
      </h4>
      <p className={classes.srvDescription} style={{ color: props.tcolor }}>
        {props.description}
      </p>
    </div>
  );
}

export default MiscSingle;
