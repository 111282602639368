import classes from './Page404.module.css';
import Content from './Content';
import SideMenuMain from '../components/SidebarMenu/SideMenuMain';
import { Link } from 'react-router-dom';

function Page404() {
  return (
    <>
      <SideMenuMain menuBackImg={'none'} />

      <div className='homeBack'>
        <Content>
          <div className={classes.fullh}>
            <div className={classes.signaturecontainer}>
              <h1 className={classes.myname}>Page Not Found: Error 404</h1>
              <div>
                <Link className={classes.buttonCasual} to='/'>
                  Home
                </Link>
                <a
                  className={classes.buttonCasual}
                  href='https://blog.nebojsazivkovic.me'
                >
                  Blog
                </a>
                <a
                  className={classes.buttonCasual}
                  href='https://wp.nebojsazivkovic.me'
                >
                  WordPress Version of this site
                </a>
                <Link className={classes.buttonCasual} to='/contact'>
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </Content>
      </div>
    </>
  );
}

export default Page404;
