import SideMenuMain from "../components/SidebarMenu/SideMenuMain";
import UpdateStatus from "../UpdateStatus";

import classes from "./PortfolioSite.module.css";

import PContent from "./PContent";
// import portfolioSitesData from "./portfolioSitesData";
import PHero from "./PHero";
import PText from "./PText";
import PBottom from "./PBottom";

import GSD from "../GlobalSiteData";

function PortfolioSite(props) {
  const portfolioRestUrl =
    GSD[0].BLOG_URL +
    "wp-json/wp/v2/portfolio?_fields=acf&_embed&acf_format=standard&per_page=100";
  const portfolioJson = UpdateStatus(portfolioRestUrl);

  /* Single portfolio element */
  const sPf = portfolioJson[props.sindex];

  /* If object is undefined - render and return nothing */
  if (!(typeof sPf !== "undefined" && sPf != null)) {
    return <></>;
  }

  // console.log(sPf);

  function stringToBoolean(ppStr) {
    switch (ppStr.toLowerCase().trim()) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return Boolean(ppStr);
    }
  }

  return (
    <>
      <SideMenuMain
        menuBackImg={sPf.acf.imgsideback}
        menuOverlayCss={sPf.acf.imgsidebackgradient}
      />
      <div className={classes.pageContainer}>
        <PContent>
          <PHero
            title={sPf.acf.title}
            herooverlaycss={sPf.acf.imgherobackgradient}
            heroback={sPf.acf.imgheroback}
            url={sPf.acf.siteurl}
            image={sPf.acf.img1}
          />
          <PText
            title1={sPf.acf.boxtitle1}
            t1={sPf.acf.boxtext1}
            title2={sPf.acf.boxtitle2}
            t2={sPf.acf.boxtext2}
            title3={sPf.acf.boxtitle3}
            t3={sPf.acf.boxtext3}
          />
          <PBottom
            img1={sPf.acf.img2}
            img2={sPf.acf.img3}
            img3={sPf.acf.img4}
            overFlowImages={stringToBoolean(sPf.acf.overflow)}
          />
        </PContent>
      </div>
    </>
  );
}

export default PortfolioSite;
