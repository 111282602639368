import classes from "./ServicesSingle.module.css";

function ServicesSingle(props) {
  return (
    <div className={classes.srvElementContainer}>
      <h4 className={classes.srvTitle}>{props.title}</h4>
      <p className={classes.srvDescription}>{props.description}</p>
    </div>
  );
}

export default ServicesSingle;
