import Content from './Content';

function Blog() {
  return (
    <Content>
      <h1>Blog</h1>
    </Content>
  );
}

export default Blog;


