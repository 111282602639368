import classes from './SidebarLogo.module.css';
import logo from '../../static/images/NebojsaZivkovicWhiteOnBlack.png';
import { Link } from 'react-router-dom';

function SidebarLogo() {
  return (
    <>
      <div className={classes.logocontainer}>
        <Link to='/'>
          <img
            src={logo}
            alt='Nebojša Živković - React Portfolio and Services'
            className={classes.logo}
          />
        </Link>
      </div>
      <div>
        <Link to='/'>
          <h6 className={classes.myname}>Nebojša Živković</h6>
        </Link>
      </div>
    </>
  );
}

export default SidebarLogo;
