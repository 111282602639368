import classes from './PContent.module.css';
import { useState, useEffect } from 'react';

/* Main content function - just holds all pages content
 */

function PContent(props) {
  const [contentVisible, changeVisible] = useState(false);

  useEffect(() => {
    changeVisible(true);
  }, [contentVisible]);

  return (
    <div
      className={classes.content}
      style={{ visibility: contentVisible ? 'visible' : 'hidden' }}
    >
      <div className={classes.contentContainer}>{props.children}</div>
    </div>
  );
}

export default PContent;
