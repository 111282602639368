import classes from "./QuickNotes.module.css";
import Content from "./Content";
import SideMenuMain from "../components/SidebarMenu/SideMenuMain";

// import TestimonialData from "./TestimonialsData";
import UpdateStatus from "../UpdateStatus";
import GSD from "../GlobalSiteData";
import SiteHelmet from "../utilities/SiteHelmet";

/* Hooks */
import { useState } from "react";

/* Libraries */
import HTMLReactParser from "html-react-parser";

function QuickNotes() {
  const [searchTerm, setSearchTerm] = useState("");

  const quickNotesRestUrl =
    GSD[0].BLOG_URL + "wp-json/wp/v2/quicknotes?per_page=100";

  const quickNotesJson = UpdateStatus(quickNotesRestUrl);

  return (
    <>
      <SideMenuMain menuBackImg={"none"} />
      <SiteHelmet searchstr="quicknotes" />

      <div className="quickNotesBack">
        <Content>
          <div className="page-title-space">
            <div className={classes.titleRow}>
              <div className={classes.titleColTitle}>
                <h1 className={classes.pagetitle}>Quick Notes ...</h1>
                <p className={classes.pageSubTitle}>
                  Quick Notes are created using headless WordPress / Custom Post
                  Type and ReactJs
                </p>
              </div>
              <div className={classes.titleColInput}>
                <input
                  type="text"
                  placeholder="Search Notes ..."
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className={classes.gridrow}>
            {Object.values(quickNotesJson)
              .filter((val) => {
                if (searchTerm === "") {
                  return val;
                } else if (
                  val.content.rendered
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  val.title.rendered
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return val;
                }
                return null;
              })
              .map((qnote, ii) => {
                return (
                  <div key={ii} className={classes.gridcol}>
                    <div className={classes.inner}>
                      <div className={"modalBox-" + ii}>
                        <h1>{qnote.title.rendered}</h1>
                        {HTMLReactParser(qnote.content.rendered)}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Content>
      </div>
    </>
  );
}

export default QuickNotes;
